










































.ECharts-com {
    box-sizing: border-box;
    height: 100% !important;
    width: 100% !important;
}
